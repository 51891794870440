import React from "react"

import Footer from "../components/footer"
import ContentBlock from "../components/contentblock"
import ContactBlock from "../components/contactblock"

export default function Services() {
  return (

    <main>
    <h1>Services</h1>

    <ContentBlock
      id="services">

      <div>
      <h3><i className="icon-develop"></i>Developing websites</h3>
      <p>Know what you want and just need someone to build it? I can provide full builds, backend and frontend, as well as integration into existing systems. These services include (but are not limited to): PSD/XD/Sketch designs to HTML templates, full WordPress builds/custom themes, single page applications and/or static site templates, interactive pieces to slot into existing sites and Laravel builds and templating.</p>
      <p>Whether your site is a one page static site, or a multi-functional application with a content management system, your site will be built with the greatest care to be mobile first, optimised, and SEO friendly.</p>
      <p>Key languages or platforms: HTML, CSS, JavaScript, React, PHP, WordPress, Laravel.</p>
   </div><div>
      <h3><i className="icon-solutions"></i>Digital solutions</h3>
      <p>Know you need something but don't know what it is? Bring me your problem and let me help you solve it! All I need is a short brief and I can consult, delivering you a recommendation report for your digital strategy, with no further obligation (though I'm happy to quote for the work!).</p>

   </div><div>
      <h3><i className="icon-support"></i>Support and maintenance</h3>
      <p>Already have a site or application but need some support? I'm available for one-off or ongoing support contracts. I can provide solution migrations, technical advice and support, SEO and optimisation audits, iterative improvements and optimisation, and ongoing solutions maintenance for both individual customers and agencies, at an hourly rate or reduced minimum monthly cost.</p>

   </div><div>
      <h3><i className="icon-agency"></i>Agency overflow</h3>
      <p>With 9 years experience working in a digital agency I know all too well how your schedules can be! Do you need someone to give some overflow work to, to completely farm out a piece of work, or get a last minute hand on an ongoing project? I'm happy to slot into your team and workflow, and pick up where needed; with my broad range of experience in both backend and frontend work, as well as client communication, unix server management, and project management skills you can put me wherever you need.</p>

   </div><div>
      <h3><i className="icon-somethingelse"></i>Need something else?</h3>
      <p>While I do not provide content strategy, graphic, or web design services myself, I work within a large network of extremely talented freelancers, so no matter how big or small your problem is, I can help you find a full solution, from a little idea right to a full launch.</p>
   </div>

    </ContentBlock>


    <ContactBlock  text="Want to see how I can help you with your next project?" />
    <Footer icon_credits="Icon credits (all from the Noun Project): Night Mode by Ravindra Kalkani. Web content, thumbs up, web app, clean code, site maintenance by Vectors Point." />

    </main>
  );
}
